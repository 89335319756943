/** COLORS
* 1. #F4FFFD tech - mint cream
* 2. #011936 porfolio - oxford blue
*
* Others in pallete
* 3. #ED254E red crayola
* 4. #F9DC5C Naples Yellow
* 5. #465362 black coral (more of a grey)

*/

/*.App {
  text-align: center;
}*/

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: black;
  height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
}

.App-header a {
  text-decoration: none;
  color: white;
}

/*.App-header a:hover {
  color: lightgray;
}*/
.body {
  overflow-x: hidden;
}

.logo-and-title {
  margin-left: 3em;
}

.logo-and-title h1 {
  display: inline;
  font-size: 20px;
}

.logo-and-title span {
  font-size: 12px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1em;
}

.my-logo {
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  width: 2rem;
}

.nav-menu {
  margin-right: 3em;
}

.nav-menu a:hover {
  color: lightgray;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sidebar-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  direction: ltr;

}

.sidebar-title h2, .sidebar-title p {
  max-width: 80%;
}

.sidebar-title p {
  font-size: 24px;
}

/*******************************************************************************************************************************************************************************/

/**************************************************************           INTRODUCTION AREA                *********************************************************************/

/*******************************************************************************************************************************************************************************/

.introduction-holder {
  height: 90vh;
  width: 100%;
  background-color: rgb(15, 15, 15);
}

/*contains the canvas and the text content*/
.introduction-container {
  height: 100%;
  width: 100%;
}
.introduction-container .introduction-text {
  position: absolute;
}

#myCanvas {
  /*change border size pixel amount to see canvas area*/
  border: 0px solid pink;
  width: 100%;
  height: 100%;
}

.introduction-text {
  position: absolute;
  right: 15%;
  top: 50%;
  transform: translateY(-50%);
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8);
  color: white;
  text-align: right;
  font-size: 34px;
  max-width: 40%;
  opacity: 0.0;
  animation: fadeIn 1s ease-in 0.5s forwards 1;

  background: rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px 10px  rgba(0, 0, 0, 0.09);
  border-radius: 20px;


}

@keyframes fadeIn {
  0% {
    opacity: 0.0;
  }
  100% {
    opacity: 1.0;
  }
}

.portrait {
  border-radius: 50%;
  width: 6em;
  height: 6em;
}

.socialMediaIcon {
  width: 2rem;
  margin-left: 1rem;
}


/*******************************************************************************************************************************************************************************/

/**************************************************************           TECHNOLOGIES AREA                *********************************************************************/

/*******************************************************************************************************************************************************************************/

.technologies-holder {
  min-height: 100vh;
  display: grid;
  grid-template-columns: 20% 80%;
  direction: rtl;
}

.technologies-left {
  /*background: #F7F3E3;*/
  background: #F4FFFD;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

/*black title area*/

.technologies-right {
  background: black;
  color: white;
}

.logo-holder {
  direction: ltr;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 70%;
}

.tech-image-holder {
  width: 8.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 2em;
  margin-bottom: 2em;
}

.tech-image {
  width: inherit;
  height: inherit;
  object-fit: contain;
  opacity: 0.01;
}

/*Animation for the drawing of the tech logo images*/

@keyframes imageAppear {
  0% {
    transform: translateX(-50px);
    opacity: 0.0;
  }
  100% {
    opacity: 1.0;
    transform: translateX(0px);
  }
}

/*******************************************************************************************************************************************************************************/

/**************************************************************           PORTFOLIO AREA                *********************************************************************/

/*******************************************************************************************************************************************************************************/

.portfolio-holder {
  min-height: 100vh;
  display: grid;
  grid-template-columns: 20% 80%;
}

/*black title area*/

.portfolio-left {
  background: black;
  color: white;
}

.portfolio-right {
  /*background: #6F1A07;*/
  background: #011936;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.portfolio-card-holder {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
  margin-right: 2em;
  flex-wrap: wrap;
}

.slider-holder {
  width: 94%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.portfolio-card {
  color: white;
  width: 50em;
  max-height: 80vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin: auto auto;
  text-align: center;
  opacity: 0.01;
  padding: 1em;
  /*border: 2px solid black;
  box-shadow: 0 8px 16px 0 rgba(54, 42, 42, 0.2);
  border-radius: 0.8em;*/
  transition: 0.3s;
}

.portfolio-card * {
  margin-left: auto;
  margin-right: auto;
}


/*Animation for the drawing of the portfolio cards*/

@keyframes portfolioAppear {
  0% {
    transform: translateX(50px);
    opacity: 0.01;
  }
  100% {
    opacity: 1.00;
    transform: translateX(0px);
  }
}

.portfolio-screenshot {
  width: 80%;
}

/* Custom scrollbar reference - https://css-tricks.com/examples/WebKitScrollbars/ */

.portfolio-card::-webkit-scrollbar {
  width: 10px;
}

/* Track */

.portfolio-card::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */

.portfolio-card::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #f3f3f3;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #f3f3f3;
}

.slick-track {
  display: flex;
  align-items: center;

}
.slick-prev {
  left: 100px;
  z-index: 999;
}
.slick-next {
  right: 100px;
}

.slick-dots li button:before {
  color: white;
}

.slick-dots li.slick-active button:before {
  color: white !important;
}

/*******************************************************************************************************************************************************************************/

/**************************************************************                 FOOTER AREA                *********************************************************************/

/*******************************************************************************************************************************************************************************/

.footer {
  height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  background-color: rgb(15, 15, 15);
  text-align: center;
}

.footer a {
  text-decoration: none;
  color: white;
}

.footer a:hover {
  color: lightgrey;
}

/**************************************
*
* Media queries
*
**************************************/
@media (max-width: 1800px) {
  /* slightly smaller screens */
  .tech-image-holder {
    width: 5rem;

  }
}


@media (max-width: 800px) {
  /* For tablets: */
  .technologies-holder {
    min-height: 100vh;
    grid: none;
    direction: ltr;
  }
  .tech-image-holder {
    width: 6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 1em;
    margin-bottom: 0.25em;
    margin-top: 0.25em;
  }
 
  .portfolio-holder {
    min-height: 100vh;
    grid: none;
  }

  .slider-holder {
    width: 80vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .portfolio-card-holder {
    width: 700px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 2em;
    margin-right: 2em;
    flex-wrap: wrap;
  }
  
  .portfolio-card {
    color: white;
    width: 35rem;
    max-height: 80vh;
   
    /*border: 2px solid black;
    box-shadow: 0 8px 16px 0 rgba(54, 42, 42, 0.2);
    border-radius: 0.8em;*/
    transition: 0.3s;
  }
  .portfolio-card p {
    font-size: 20px;
  }
  .portfolio-screenshot {
    width: 33em;
  }
  
  .slick-slider {
    display: table !important;
    table-layout: fixed !important;
    width: 100% !important;
  }

  .slick-slide {
    box-sizing: border-box;
  }

  
}

@media (max-width: 450px) {
  
  /* For phones: */

  .socialMediaIcon {
    width: 1.5rem;
    margin-left: 0.5rem;
  }


  .App-header {
    background-color: black;
    height: 20vh; /* introduction section will be 80vh */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
  }
  .logo-and-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0;
    margin-bottom: 1em;
  }
  .logo-and-title span {
    margin-left: 0;
  }
  .my-logo {
    margin-right: 0;
    margin-bottom: 0;
    margin-top: 1rem;
    width: 2rem;
  }
  .nav-menu {
    margin-right: 0;
    margin-bottom: 0.5em;
  }
  .introduction-holder {
    height: 80vh; /* header will be 20vh */

  }



  .introduction-text {
    font-size: 15px;
    max-width: 50%;

    
  }
  .portrait {
    width: 5rem;
    height: 5rem;
  }

  .sidebar-title {
    padding-top:1.5em;
    padding-bottom:1em;
  }
  .sidebar-title h2 {
    font-size: 24px;
  }
  .sidebar-title p {
    font-size: 14px;
  }

  .technologies-holder {
    min-height: 100vh;
    grid: none;
    direction: ltr;
  }
  .tech-image-holder {
    width: 3rem;
    margin-right: 1em;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
  }


  .slider-holder {
    width: 99vw;
  }
  .slick-prev {
    left: 15px;
    z-index: 999;
}
.slick-next {
    right: 15px;
}
  
  .portfolio-holder {
    min-height: 80vh;
    grid: none;
  }
  


  .portfolio-card-holder {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 1em;
    margin-right: auto;
    flex-wrap: wrap;
  }
  
  .portfolio-card {
    color: white;
    width: 20rem;
    max-height: 80vh;
   
    /*border: 2px solid black;
    box-shadow: 0 8px 16px 0 rgba(54, 42, 42, 0.2);
    border-radius: 0.8em;*/
    transition: 0.3s;
  }
  .portfolio-card p {
    font-size: 12px;
  }
  .portfolio-screenshot {
    width: 18em;
  }

  .footer {
    height: 30vh;
    font-size: 16px;
  }
  .footer p {
    margin-left: 1em;
    margin-right: 1em;
  }
}